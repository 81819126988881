import React, {useEffect, useState} from "react";
import moment from "moment";
import Token from "../../auth/Token";
import dataService from "../../../services/data.service";
import Datatable from "../../dataTable/DataTable";
import Breadcrumb from "../../Breadcrumb";


const CurrenciesList = () => {
    const config = {
        create: {
            link: './create',
            icon: 'icon-Plus',
            title: 'تعریف العمله'
        }
    };
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'name',
            title: 'اسم العمله',
            value: function (model) {
                return model.name;
            }
        },
        {
            key: 'salesPrice',
            title: 'قیمت فروش',
            value: function (model) {
                return model.salesPrice;
            }
        },
        {
            key: 'purchasePrice',
            title: 'قیمت خرید',
            value: function (model) {
                return model.purchasePrice;
            }
        },
        {
            key: 'created_at',
            title: 'تاريخ الانشاء',
            value: function (model) {
                return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
            }
        },
        {
            key: 'name',
            title: '',
            value: function (model) {
                return '';
            }
        }
    ];
    const actions = [
        {
            link: '/currency/update/{id}',
            icon: 'icon icon-Edit',
            type: 'link'
        },
        {
            link: '/currency/delete/{id}',
            icon: 'icon icon-Cancel',
            type: 'delete'
        }
    ];
    const {token} = Token();
    const ds = new dataService(token, 'currencies');
    const  breadcrumb=[
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/setting",
            name: "اعدادات"
        },
        {
            link: "/currency",
            name: "قائمة"
        }
    ]
    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <Datatable entity="users"
                       dataService={ds}
                       columns={columns}
                       actions={actions}
                       config={config}/>
        </div>

    )


}

export default CurrenciesList
