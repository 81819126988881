import React, {useEffect, useState} from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import AccountingDataTable from "../dataTable/AccountingDataTable";
import Breadcrumb from "../Breadcrumb";
import TransferDataTable from "./transferDataTable";

const TransferList = (props) => {

    const config = {
        export: {
            link: './export',
            icon: 'icon-Save',
            title: ''
        }
    };
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'number',
            title: 'رقم الوصل',
            value: function (model) {
                return model.number;
            }
        },
        {
            key: 'date',
            title: 'تاریخ الوصل',
            value: function (model) {
                return moment(model.date).format("YYYY/MM/DD hh:mm:ss");
            }
        },
        {
            key: 'currency_id',
            title: 'نوع العمل',
            value: function (model) {
                return model?.currency?.name;
            }
        },
        {
            key: 'price',
            title: 'مبلغ الوصل',
            value: function (model) {
                return model.price;
            }
        },
        {
            key: 'deposit_account_name',
            title: 'اسم له',
            value: function (model) {
                return model?.deposit?.account?.name;
            }
        },
        // {
        //     key: 'deposit_subaccount_name',
        //     title: 'له فرع الحساب',
        //     value: function (model) {
        //         return model?.deposit?.subaccount?.name;
        //     }
        // },
        {
            key: 'withdraw_account_name',
            title: 'اسم لنا',
            value: function (model) {
                return model?.withdraw?.account?.name;
            }
        },
        // {
        //     key: 'withdraw_subaccount_name',
        //     title: 'لنا فرع الحساب',
        //     value: function (model) {
        //         return model?.withdraw?.subaccount?.name;
        //     }
        // },
        {
            key: 'description',
            title: 'الملاحظات',
            value: function (model) {
                return model?.description;
            }
        },
        {
            key: 'user',
            title: 'المستخدم',
            value: function (model) {
                return model?.username;
            }
        }

    ];
    const actions = [];
    const {token} = Token();
    const ds = new dataService(token, 'accounting');

    const breadcrumb = [
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: props.type === "deposit" ? "/transfer/list/deposit" : "/transfer/list/withdraw",
            name: props.type === "deposit" ? "قائمة المصروف له" : "قائمة المصروف لنا"
        },

    ]


    return (
        <>
            <Breadcrumb data={breadcrumb}/>
            <TransferDataTable
                entity="transaction"
                type={props.type}
                dataService={ds}
                columns={columns}
                actions={actions}
                config={config}/>
        </>
    )
};

export default TransferList
