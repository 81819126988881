import Home from './pages/home'
import React, {useReducer, useState} from "react";
import AccountForm from './account/AccountForm'
import SubAccountForm from "./subAccount/Form";
import CurrencyForm from "./currency/Form";
import './app.css'

import {
    Route,
    Routes,
} from 'react-router-dom'
import Layout from './layout/layout';
import Login from "./auth/login";
import Cheque from "./cheque/cheque";
import Transfer from "./transfer/transfer";
import FiscalYearForm from "./fiscalYear/Form";
import AccountList from "./pages/account/accountList";
import SubAccountList from "./pages/subAccount/subAccountList";
import CurrenciesList from "./pages/currency/currenciesList";
import FiscalYearList from "./pages/fiscalYear/fiscalYearList";
import Token from "./auth/Token";
import UserForm from "./user/UserForm";
import UserList from "./user/UserList";
import Exchange from "./exchange/exchange";
import Setting from "./setting/Setting";
import Accounting from "./accounting/Accounting";
import CustomerForm from "./customer/CustomerForm";
import CustomerList from "./customer/CustomerList";
import CorrespondList from "./correspond/CorrespondList";
import CorrespondForm from "./correspond/CorrespondForm";
import JardForm from "./jard/jardFrom";
import List from "./jard/List";
import Taslim from "./taslim/Taslim";
import TransferList from "./transfer/transferList";
import ChequeList from "./cheque/chequeList";

function App() {

    const {token, setToken} = Token();

    if (!token) {
        return <Login setToken={setToken}/>
    }
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>


                <Route path="/fiscalyear/create" element={<FiscalYearForm/>}/>
                <Route path="/fiscalyear/update/:Id" element={<FiscalYearForm/>}/>
                <Route path="/fiscalyear" element={<FiscalYearList/>}/>

                <Route path="/account" element={<AccountList/>}/>
                <Route path="/account/create" element={<AccountForm/>}/>
                <Route path="/account/update/:id" element={<AccountForm/>}/>

                <Route path="/currency/create" element={<CurrencyForm/>}/>
                <Route path="/currency/update/:Id" element={<CurrencyForm/>}/>
                <Route path="/currency" element={<CurrenciesList/>}/>

                <Route path="/customer/create" element={<CustomerForm/>}/>
                <Route path="/customer/update/:Id" element={<CustomerForm/>}/>
                <Route path="/customer" element={<CustomerList/>}/>

                <Route path="/account/sub" element={<SubAccountList/>}/>
                <Route path="/account/sub/create" element={<SubAccountForm/>}/>
                <Route path="/account/sub/update/:Id" element={<SubAccountForm/>}/>

                <Route path="/transfer/withdraw" element={<Transfer type="payer"/>}/>
                <Route path="/transfer/withdraw/:id" element={<Transfer type="payer"/>}/>
                <Route path="/transfer/deposit" element={<Transfer type="receiver"/>}/>
                <Route path="/transfer/deposit/:id" element={<Transfer type="receiver"/>}/>
                <Route path="/transfer/list/deposit" element={<TransferList type="deposit"/>}/>
                <Route path="/transfer/list/withdraw" element={<TransferList type="withdraw"/>}/>

                <Route path="/exchange/seller" element={<Exchange situation="1"/>}/>
                <Route path="/exchange/buyer" element={<Exchange situation="2"/>}/>
                <Route path="/exchange/seller/:id" element={<Exchange situation="1"/>}/>
                <Route path="/exchange/buyer/:id" element={<Exchange situation="2"/>}/>

                <Route path="/cheque-in" element={<Cheque mode="in"/>}/>
                <Route path="/cheque-out" element={<Cheque mode="out"/>}/>
                <Route path="/cheque-in/:id" element={<Cheque mode="in"/>}/>
                <Route path="/cheque-out/:id" element={<Cheque mode="out"/>}/>
                <Route path="/cheque-in/list" element={<ChequeList mode="in"/>}/>
                <Route path="/cheque-out/list" element={<ChequeList mode="out"/>}/>

                <Route path="/users" element={<UserList/>}/>
                <Route path="/users/create" element={<UserForm/>}/>
                <Route path="/users/update/:Id" element={<UserForm/>}/>

                <Route path="/setting" element={<Setting/>}/>

                <Route path="/accounting" element={<Accounting/>}/>

                <Route path="/correspond" element={<CorrespondList/>}/>
                <Route path="/correspond/create" element={<CorrespondForm/>}/>
                <Route path="/correspond/:Id" element={<CorrespondForm/>}/>

                <Route path="/jard/create" element={<JardForm />}/>
                <Route path="/jard/update/:Id" element={<JardForm />}/>
                <Route path="/jard/" element={<List />}/>
                <Route path="/taslim" element={<Taslim/>}/>

            </Routes>
        </Layout>
    );
}


export default App;
