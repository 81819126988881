import React, {useEffect, useState} from "react";
import './transfer.css';
import Button from "../button/Button";
import DatePicker from "react-datepicker";
import {BaseUrl} from "../../utilities/StaticProvider";
import CurrencyInput from 'react-currency-input-field';
import moment from "moment";
import {isEmpty} from "../../shared/validator";
import translate from "../../message/avii";
import {NotificationManager} from "react-notifications";
import Token from "../auth/Token";
import {v4 as uuidv4} from 'uuid';
import {FormText} from "react-bootstrap";
import Breadcrumb from "../Breadcrumb";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import Deposit from "./deposit";
import Withdraw from "./withdraw";
import  { Navigate } from 'react-router-dom';
import dataService from "../../services/data.service";

const Transfer = (props) => {
    const navigate = useNavigate();
    const {token} = Token();
    const ds = new dataService(token, 'transactions');
    const account = useSelector((state) => state.account);
    const [price, setPrice] = useState(0);
    const defaultWithdraw = {
        account_id: account && props.type === 'payer' ? account.id : null,
        type: 1,
        description: "",
        price: 0,
        number: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
        subaccount_id: null,
    };
    const defaultDeposit = {
        account_id: account && props.type === 'receiver' ? account.id : null,
        type: 2,
        description: "",
        price: 0,
        number: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
        subaccount_id: null,
    };
    const {getUser} = Token();
    const [startDate, setStartDate] = useState(new Date());
    const [state, setState] = useState({
        formData: {
            transactions: [],
            number: Math.floor(100000 + (Math.random() * (1000000 - 100000))),
            date: moment(startDate).format("YYYY/MM/DD hh:mm:ss"),
            price: 0,
            currency_id: null,
            reason: "a reason",
            description: "",
            user_id: getUser().id,
        },
        withdraws: [defaultWithdraw],
        deposits: [defaultDeposit],
        errors: {},
        formSubmitted: false,
        loading: false
    });
    const [currencies, setCurrencies] = useState([]);
    const [accounts, setAccounts] = useState([]);
    let {id} = useParams();

    const loadCurrencies = async () => {
        ds.model = 'currency';
        return ds.getAll({
            per_page: 25
        })
            .then(response => {
                setCurrencies(response.data.data)

            })
            .catch(error => {
                console.error(error);
                throw error;
            })
            .finally(() => {
                ds.model = 'transactions';
            });
    };
    const loadAccounts = async () => {
        ds.model = 'account';
        return ds.getAll({
            per_page: 25
        })
            .then(response => {
                setAccounts(response.data)

            })
            .catch(error => {
                console.error(error);
                throw error;
            })
            .finally(() => {
                ds.model = 'transactions';
            });
    };


    useEffect(async () => {
        if (id) {
            getTransfer(id);
        }
        await loadCurrencies();
        await loadAccounts();
    }, []);

    const validateForm = (e) => {
        let errors = {};
        const {formData} = state;
        formData.transactions = [];
        let transactions = [];
        const {withdraws, deposits} = state;

        if (isEmpty(formData.date)) {
            errors.date = translate.ar.forms["transfer date can not be empty."];
        }
        if (isEmpty(formData.price)) {
            errors.price = translate.ar.forms["transfer price can not be empty."];
        }

        if (isEmpty(formData.currency_id)) {
            errors.currency_id = translate.ar.forms["transfer currency can not be empty."];
        }

        [...withdraws, ...deposits].forEach(item => {
            errors[item.number] = {};

            if (isEmpty(item.price)) {
                errors[item.number].price = translate.ar.forms["transfer amount can not be empty."];
            }

            if (Object.keys(errors[item.number]).length === 0) {
                delete errors[item.number];
                if (!isEmpty(item.account_id)) {
                    delete item.cashDesk;
                    transactions.push(item);
                }
                if (!isEmpty(item.cashDesk) && item.cashDesk >0){
                    delete item.account_id;
                    item.subaccount_id=0;
                    transactions.push(item);
                }
            }

        });


        formData.transactions = transactions;
        setState({...state, formData: formData});

        if (transactions.length === 0) {
            errors.result = "transaction items is empty!";
        } else {
            delete errors.result;
        }


        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }

    };

    function handlePayerItemsChange(event, number) {
        let {withdraws} = state;
        withdraws = withdraws.map(item => {
            if (number !== item.number) return item;

            if (event.target.name === 'cashDesk' && item.hasOwnProperty('cashDesk')){
                if (item.cashDesk === 1){
                    event.target.value=0;
                }   else{
                    event.target.value=1
                }
            }
            return {...item, [event.target.name]: event.target.value};
        });
        setState({...state, withdraws: withdraws});

    }

    function handleReceiverItemsChange(event, number) {
        let {deposits} = state;
        deposits = deposits.map(item => {
            if (number !== item.number) return item;

            if (event.target.name === 'cashDesk' && item.hasOwnProperty('cashDesk')){
                if (item.cashDesk === 1){
                    event.target.value=0;
                }   else{
                    event.target.value=1
                }
            }
            return {...item, [event.target.name]: event.target.value};
        });
        setState({...state, deposits: deposits});
    }

    function handleAddPayerItem() {
        let {withdraws} = state;
        withdraws = withdraws.concat([defaultWithdraw]);
        setState({...state, withdraws: withdraws});
    }

    function handleAddReceiverItem() {
        let {deposits} = state;
        deposits = deposits.concat([defaultDeposit]);
        setState({...state, deposits: deposits});
    }

    function handleRemovePayerItem(number) {
        let {withdraws} = state;
        withdraws = withdraws.filter(item => number !== item.number);
        setState({...state, withdraws: withdraws});
    }

    function handleRemoveReceiverItem(number) {
        let {deposits} = state;
        deposits = deposits.filter(item => number !== item.number);
        setState({...state, deposits: deposits});
    }

    function handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let {formData} = state;
        formData[name] = value;

        setState({...state,formData: formData})
    }

    function handleChangeAmount(value, name) {

        let {formData} = state;
        let price = +value;
        formData[name] = price;
        formData.transactions = [];

        setState({...state, formData: formData});


        setPrice(price);
        //update send and receive price
        let {withdraws, deposits} = state;

        let updateSends = withdraws.map(item => {
            return {...item, price: price};
        });

        let updateReceives = deposits.map(item => {
            return {...item, price: price};
        });

        setState({...state, deposits: updateReceives, withdraws: updateSends});
        //console.log(withdraws, deposits);
        // console.log(state, updateSends);

    }

    async function handleSubmit(event) {
        event.preventDefault();
        submit()
    }

    async function submit() {
        let errors = validateForm();
        if (errors === true) {
            if (id) {
                updateModel();
            } else {
                saveModel();
            }
            return true;
        } else {
            setState({
                ...state,
                errors: errors,
                formSubmitted: true
            });
            return false;
        }
    }

    function getTransfer(id) {

        fetch(BaseUrl + "accounting/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result.success) {
                        let errors = {};
                        setState({
                            ...state,
                            errors: errors,
                            formSubmitted: true
                        });
                    } else {
                        updateFormData(result.data);
                    }
                },
                (error) => {
                    setState({
                        ...state,
                        loading: true,
                        errors: {error}
                    });
                }
            );
    }

    function updateFormData(data) {
        return new Promise((resolve, reject) => {
            try {
                let formData = {};

                formData.transactions = data.transactions;
                formData.id = data.id;
                formData.number = data.number;
                formData.date = data.date;
                formData.price = data.price;
                formData.currency_id = data.currency_id;
                formData.reason = data.reason;
                formData.user_id = data.user_id;
                data.transactions.forEach(item => {
                    let transaction = {
                        id: item.id,
                        type: item.type,
                        account_id: item.account_id,
                        subaccount_id: item.subaccount_id,
                        description: item.description,
                        price: item.price
                    };

                    state.deposits = [];
                    state.withdraws = [];

                    if (+transaction.type === 2) {
                        state.deposits.push(transaction);
                    } else {
                        state.withdraws.push(transaction);
                    }

                    if (state.withdraws.length === 0) {
                        state.withdraws.push(defaultWithdraw);
                    }

                    if (state.deposits.length === 0) {
                        state.deposits.push(defaultDeposit);
                    }

                    setState({...state, formData: formData});


                });


                resolve(true);
            } catch (e) {
                reject(e);
            }
        });
    }

    function updateTransactions(transactions) {
        //let {receives,sends} = state;
        const {formData} = state;

        setState({
            ...state,
            formData: formData
        });
    }

    function saveModel() {

        const {formData} = state;

        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        };


        fetch(BaseUrl + "accounting", options)
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result.success) {
                        let errors = {};
                        NotificationManager.error(translate.ar.global["The username or password is incorrect"], 'مستخدم تسجيل الدخول');
                        setState({
                            ...state,
                            errors: errors,
                            formSubmitted: true
                        });
                    } else {
                        NotificationManager.success('تسجيل الدخول بنجاح', 'مستخدم تسجيل الدخول');
                        return <Navigate to='/'  />
                    }
                },
                (error) => {
                    setState({
                        ...state,
                        loading: true,
                        errors: {error}
                    });
                }
            );
    }

    function updateModel() {

        const {formData} = state;

        let options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        };

        fetch(BaseUrl + "accounting/" + id, options)
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result.success) {
                        let errors = {};
                        NotificationManager.error(translate.ar.global["error in update transaction model"], 'error');
                        setState({
                            ...state,
                            errors: errors,
                            formSubmitted: true
                        });
                    } else {
                        NotificationManager.success('the transaction model saved successfully', 'success');
                        return <Navigate to='/'  />
                    }
                },
                (error) => {
                    setState({
                        ...state,
                        loading: true,
                        errors: {error}
                    });
                }
            );
    }

    function handleDateChange(date) {
        setState({
            ...state,
            formData: {
                ...state.formData,
                date: moment(date).format("YYYY/MM/DD hh:mm:ss")
            }
        });
        setStartDate(date);
    }

    function renderDeposit() {
        return state.deposits && state.deposits.map((item, key) => {
            let {errors} = state;
            return (
                <div className="col-md-12">
                    <div className="form-row">
                        <Deposit
                                 onAddItem={handleAddReceiverItem}
                                 onDeleteItem={handleRemoveReceiverItem}
                                 onChange={handleReceiverItemsChange}
                                 dataService={ds}
                                 errors={errors[item.number]}
                                 type={props.type}
                                 id={item.number}
                                 number={item.number}
                                 account_id={item.account_id}
                                 subaccount_id={item.subaccount_id}
                                 description={item.description}
                                 price={item.price}
                                 count={key}
                        />
                    </div>
                </div>
            )
        })
    }

    function renderWithdraw() {
        return (
            state.withdraws && state.withdraws.map((item, key) => {
                let {errors} = state;
                return (
                    <div className="col-md-12">
                        <div className="form-row">
                            <Withdraw
                                      onAddItem={handleAddPayerItem}
                                      onDeleteItem={handleRemovePayerItem}
                                      onChange={handlePayerItemsChange}
                                      dataService={ds}
                                      errors={errors[item.number]}
                                      item={item}
                                      number={item.number}
                                      type={props.type}
                                      id={item.number}
                                      account_id={item.account_id}
                                      subaccount_id={item.subaccount_id}
                                      description={item.description}
                                      price={item.price}
                                      count={key}
                            />
                        </div>
                    </div>
                )
            })
        )
    }

    function render() {
        return (
            <div className="col-md-12">
                <div className="form-row">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-row">
                                <input name="id"
                                       id="id"
                                       type="hidden"
                                       value={state.formData.id}
                                />
                                <div className="form-group col-md-3">
                                    <label htmlFor="id">رقم الوصل : </label>
                                    <input name="number"
                                           id="number"
                                           type="text"
                                           disabled="disabled"
                                           value={state.formData.number}
                                           className="form-control silver"
                                           onChange={handleChange}
                                           required
                                    />
                                    {state.errors.id &&
                                    <FormText>{state.errors.id}</FormText>
                                    }
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="date">تاریخ الوصل : </label>
                                    <DatePicker
                                        name="date"
                                        id="date"
                                        className="form-control silver"
                                        dateFormat="yyyy/MM/dd"
                                        selected={startDate}
                                        value={state.formData.date}
                                        strictParsing
                                        closeOnScroll={true}
                                        onChange={(date) => handleDateChange(date)}
                                    />
                                    {state.errors.date &&
                                    <FormText>{state.errors.date}</FormText>
                                    }
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="currency_id">نوع العمل : </label>
                                    <select className="form-control silver"
                                            name="currency_id"
                                            onChange={handleChange}
                                            value={state.formData.currency_id}
                                            id="currency_id">
                                        <option value="0" disabled selected>{translate.ar.forms["انتخاب کنید"]}</option>

                                        {
                                            currencies.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })
                                        }
                                    </select>
                                    {state.errors.currency_id &&
                                    <FormText>{state.errors.currency_id}</FormText>
                                    }
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="price">مبلغ الوصل : </label>
                                    <CurrencyInput
                                        id="price"
                                        name="price"
                                        className="form-control silver"
                                        type="search"
                                        value={state.formData.price}
                                        decimalsLimit={2}
                                        onValueChange={(value, name) => {
                                            handleChangeAmount(value, name)
                                        }}
                                    />
                                    {state.errors.price &&
                                    <FormText>{state.errors.price}</FormText>
                                    }
                                </div>
                                <div className="col-md-12 form-group">
                                    <label htmlFor="name">{translate.ar.global["description"]}</label>
                                    <textarea name="description"
                                              cols="10"
                                              rows="2"
                                              onChange={handleChange}
                                              value={state.formData.description}
                                              className="form-control silver"/>
                                    {state.errors.description &&
                                    <FormText>{state.errors.description}</FormText>
                                    }
                                </div>
                                <div className="col-md-12 form-group">
                                    {state.errors.result &&
                                    <FormText>{state.errors.result}</FormText>
                                    }
                                </div>
                                <div className="col-md-12 form-group btns-group">
                                    <Button
                                        handleClick={handleSubmit}
                                        type="submit"
                                        className="green"
                                    >
                                        <span className="icon-Save"/>تسجیل
                                    </Button>

                                    <Button
                                        handleClick={() => {
                                            // Navigate to dashboard page
                                            navigate("/")
                                        }}
                                        className="blue"
                                    >
                                        <span className="icon-Cancel"/>الغاء
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const breadcrumb = [
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: props.type === "payer" ? "/transfer/payer" : "/transfer/receiver",
            name: props.type === "payer" ? "المصروف له" : "المصروف لنا",
        }
    ]

    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <form className="form" id="transfer-form">
                <div className="form-row1">
                    {render()}
                    {renderDeposit()}
                    {renderWithdraw()}
                </div>
            </form>
        </div>
    )
};

export default Transfer
