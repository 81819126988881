import React, {useEffect, useState} from "react";
import List from "../../list/list"
import moment from "moment";
import Token from "../../auth/Token";
import dataService from "../../../services/data.service";
import Datatable from "../../dataTable/DataTable";
import Breadcrumb from "../../Breadcrumb";


const AccountList = () => {
    const config = {
        create: {
            link: '/account/sub/create',
            icon: 'icon-Plus',
            title: 'تعریف الحساب فرعی'
        }
    };
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'name',
            title: 'نام',
            value: function (model) {
                return model.name;
            }
        },
        {
            key: 'created_at',
            title: 'تاريخ الانشاء',
            value: function (model) {
                return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
            }
        },
        {
            key: 'name',
            title: '',
            value: function (model) {
                return '';
            }
        }
    ];
    const actions = [
        {
            link: '/account/sub/update/{id}',
            icon: 'icon icon-Edit',
            type: 'link'
        },
        {
            link: '/account/sub/delete/{id}',
            icon: 'icon icon-cancel',
            type: 'link'
        }
    ];
    const breadcrumb = [
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/setting",
            name: "اعدادات"
        },
        {
            link: "/account/sub/",
            name: "قائمة"
        }
    ]

    const {token} = Token();
    const ds = new dataService(token, 'subaccount');
    return (
        <div class="col-md-12">
            <Breadcrumb data={breadcrumb}/>
            <Datatable entity="users"
                       dataService={ds}
                       columns={columns}
                       actions={actions}
                       config={config}/>
        </div>
    )


}

export default AccountList
