import React, {useContext, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import connect from "react-redux/es/connect/connect";
import CurrencyInput from "react-currency-input-field";
import {FormText} from "react-bootstrap";
import {BaseUrl} from "../../utilities/StaticProvider";
import AsyncSelect from "react-select/async";
import {useLocation} from "react-router";

const Withdraw = (props) => {
    const navigationParams = useLocation();
    const account = navigationParams.state?.withdraw_account;
    const dispatch = useDispatch();
    const [subAccount, setSubAccount] = useState([]);
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: state.isFocused ? "#fff" : "#e8e8e8",
            borderRadius: "8px",
            borderColor: "#e8e8e8",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            padding: 0
        })
    };
    const [selectedOption, setSelectedOption] = useState(null);
    const [state, setState] = useState({
        entities: {
            data: [],
            models: "",
            meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 20,
                to: 1,
                total: 1,
            },
        },
        first_page: 1,
        current_page: 1,
        offset: 4,
        order: 'asc',
        q: '',
        account_id: account ? account.id : null,
        currency_id: null,
        type: null,
        loader: true
    });
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        if (account) {
            setSelectedOption({value: account.id, label: account.name})
        }
        loadAccounts();
    }, []);

    const loadAccounts = async () => {
        props.dataService.model = 'account';
        return props.dataService.getAll({
            per_page: 25
        })
            .then(response => {
                let options = [];
                response.data.data.map(entity => {
                    options.push({
                        label: entity.name ? entity.name : "",
                        value: entity.id
                    });

                });

                setAccounts(options);

            })
            .catch(error => {
                console.error(error);
                throw error;
            })
            .finally(() => {
                props.dataService.model = 'transactions';
            });
    };
    let changeAccount = (el) => {
        let state = 0;

        accounts.forEach(element => {
            if (element.id === +el.target.value) {
                state = element
            }
        });
        if (state === 0) {
            setSubAccount([])
        } else {
            setSubAccount(state["sub_account"])
        }
    };

    function changeQuery(inputValue, callback) {
        if (inputValue.length > 2) {
            fetchAutoComplete(inputValue, callback);
        }
    }

    function fetchAutoComplete(inputValue, callback) {
        props.dataService.model = 'accounts';
        return props.dataService.getAll({
            q: inputValue,
            per_page: 5
        })
            .then(response => {
                let options = [];
                response.data.data.map(entity => {
                    options.push({
                        label: entity.name ? entity.name : "",
                        value: entity.id
                    });

                });

                setAccounts(options);

                if (callback) {
                    callback(options);
                }
            })
            .catch(error => {
                console.error(error);
                throw error;
            })
            .finally(() => {
                props.dataService.model = 'transactions';
            });
    }


    function duplicate() {
        if (props.count === 0) {
            return (
                <button type="button" className="add-button" onClick={() => props.onAddItem(props.id)}>
                    <span className="icon-Plus"/>
                </button>
            )
        } else {
            return (
                <button type="button" className="remove-button" onClick={() => props.onDeleteItem(props.number)}>
                    <span className="icon-Cancel"/>
                </button>
            )
        }
    }

    function applyFilter(name = '', value = '') {
        if (name === 'account_id') {
            state.account_id = value.value;
        } else if (name === 'currency_id') {
            state.currency_id = value;
        }

        //
        // +ز fetchEntities();

    }

    function render() {
        return (
            <div className="form-row">
                <input name="id"
                       id="id"
                       type="hidden"
                       value={props.id}
                />
                <div className="col-md-4 form-group">
                    <label htmlFor="name">اسم لنا :</label>
                    <AsyncSelect
                        defaultOptions={accounts}
                        loadOptions={changeQuery}
                        value={selectedOption}
                        name="account_id"
                        styles={customStyles}
                        className="minimal"
                        isClearable
                        onChange={
                            (e) => {
                                setSelectedOption(e);
                                if (e !== null) {
                                    let el = {
                                        target: {
                                            value: e.value,
                                            name: 'account_id'
                                        }
                                    }
                                    props.onChange(el, props.id);
                                    changeAccount(el);
                                }
                            }
                        }
                    />
                    {/*<select className="form-control silver"
                            name="account_id"
                            value={props.account_id}
                            onChange={(el) => {
                                props.onChange(el, props.id);
                                changeAccount(el);
                            }}
                            id="account_id">
                        <option value="0">{"اختيار"}</option>
                        {
                            props.accounts.map((item) => {
                                if (account && item.id === account.id && props.type === "payer") {
                                    return <option value={item.id} selected="selected">{item.name}</option>;
                                } else {
                                    return <option value={item.id}>{item.name}</option>;
                                }
                            })
                        }
                    </select>*/}
                    {props.errors && props.errors.account_id &&
                    <FormText className="text-white">{props.errors.account_id}</FormText>
                    }
                </div>
                <div className="col-md-4 form-group">
                    <label htmlFor="name">المبلغ :</label>
                    <CurrencyInput
                        id="price"
                        name="price"
                        className="form-control silver"
                        value={props.price}
                        decimalsLimit={2}
                        onValueChange={(value, name) => props.onChange({
                            target: {
                                value,
                                name
                            }
                        }, props.number)}
                    />
                    {props.errors && props.errors.price &&
                    <FormText className="text-white">{props.errors.price}</FormText>
                    }
                </div>
                <div className="col-md-4 form-group">
                    <label htmlFor="subaccount_id">فرع الحساب :</label>
                    <select className="form-control silver"
                            value={props.subaccount_id}
                            name="subaccount_id"
                            onChange={(el) => props.onChange(el, props.id)}>
                        <option value="0">{"اختيار"}</option>
                        {
                            subAccount?.map((item) => {
                                return <option value={item.id}>{item.name}</option>;
                            })
                        }
                    </select>
                    {props.errors && props.errors.subaccount_id &&
                    <FormText className="text-white">{props.errors.subaccount_id}</FormText>
                    }
                </div>
                <div className="col-md-12 form-group">
                    <label htmlFor="name">الملاحظات : </label>
                    <textarea name="description"
                              cols="5"
                              rows="2"
                              value={props.description}
                              className="form-control silver"
                              onChange={(el) => props.onChange(el, props.number)}
                    />
                    {props.errors && props.errors.description &&
                    <FormText className="text-white">{props.errors.description}</FormText>
                    }
                </div>
                <div className="form-group col-md-12">
                    <label className="el-switch" htmlFor={`cashDeskSeller_`+props.id}>
                        <span className="margin-l">الصندوق</span>
                        <input type="checkbox" name="cashDeskSeller" id={`cashDeskSeller_`+props.id}   value="1"/>
                        <span className="el-switch-style"/>
                    </label>
                </div>
            </div>
        )
    }

    return (
        <div className={props.type === "payer" ? "card  active" : "card  "}>
            <div className="card-body">
                <div className="form-row">
                    {duplicate()}
                    {render()}
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: () => dispatch({type: 'SET_ACCOUNT'}),
        clearAccount: () => dispatch({type: 'CLEAR_ACCOUNT'})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw)

