import React from "react";
import avii from "../../message/avii";
import './setting.css';
import UserList2 from "../user/UserList2";
import {Link} from "react-router-dom";
import Breadcrumb from "../Breadcrumb";

const Setting = (props) => {
    const  breadcrumb=[
            {
                link: "/",
                name: "الرئيسية"
            },
        {
            link: "/setting",
            name: "اعدادات"
        },


        ]


    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-row setting">

                        <div className="col-md-2 form-group">
                            <button className="setting-card">
                                <Link to="/users">
                                    <div className="setting-card-icon">
                                        <i className="icon icon-user"/>
                                    </div>
                                    <div className="setting-card-title">
                                        {avii.ar.pages.settings['list user']}
                                    </div>
                                </Link>
                            </button>
                        </div>
                        <div className="col-md-2 form-group">
                            <button className="setting-card">
                                <Link to="/customer">
                                    <div className="setting-card-icon">
                                        <i className="icon icon-user"/>
                                    </div>
                                    <div className="setting-card-title">
                                        {avii.ar.pages.settings["list customer"]}
                                    </div>
                                </Link>
                            </button>
                        </div>

                        <div className="col-md-2 form-group">
                            <button className="setting-card">
                                <Link to="/account">
                                    <div className="setting-card-icon">
                                        <i className="icon icon-user"/>
                                    </div>
                                    <div className="setting-card-title">
                                        {avii.ar.pages.settings["list account"]}
                                    </div>
                                </Link>
                            </button>
                        </div>

                        <div className="col-md-2 form-group">
                            <button className="setting-card">
                                <Link to="/currency">
                                    <div className="setting-card-icon">
                                        <i className="icon icon-Money-3"/>
                                    </div>
                                    <div className="setting-card-title">
                                        {avii.ar.pages.settings["list currency"]}
                                    </div>
                                </Link>
                            </button>
                        </div>

                        <div className="col-md-2 form-group">
                            <button className="setting-card">
                                <Link to="/account/sub">
                                    <div className="setting-card-icon">
                                        <i className="icon icon-Far-ol-Hesab"/>
                                    </div>
                                    <div className="setting-card-title">
                                        {avii.ar.pages.settings["list subaccount"]}
                                    </div>
                                </Link>
                            </button>
                        </div>

                        <div className="col-md-2 form-group">
                            <button className="setting-card">
                                <Link to="/fiscalyear">
                                    <div className="setting-card-icon">
                                        <i className="icon icon-Al-senate-Maleyh"/>
                                    </div>
                                    <div className="setting-card-title">
                                        {avii.ar.pages.settings["list fiscalyear year"]}
                                    </div>
                                </Link>
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    )
};

export default Setting
