import React, {useEffect, useState} from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Breadcrumb from "../Breadcrumb";
import  { Navigate } from 'react-router-dom';

const Taslim = (props) => {
    const config = {};
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'customer_rec',
            title: 'المستفیذ',
            value: function (model) {
                return model?.in?.customer?.name;
            }
        },
        {
            key: 'customer_rec_name',
            title: 'حساب المستلم',
            value: function (model) {
                return model?.in?.account?.name;
            }
        },
        {
            key: 'price',
            title: 'المبلغ الحواله ',
            value: function (model) {
                return model.price;
            }
        },
        {
            key: 'customer_snd_name',
            title: 'حساب الدافع',
            value: function (model) {
                return model?.out?.account?.name;
            }
        },
        {
            key: 'date',
            title: 'تاریخ الحواله',
            value: function (model) {
                return model.date;
            }
        },
        {
            key: 'customer_snd',
            title: 'المرسل',
            value: function (model) {
                return model?.out?.customer?.name;
            }
        },
        {
            key: 'currency',
            title: 'العمل  ',
            value: function (model) {
                return model?.currency?.name;
            }
        },
        {
            key: 'number',
            title: 'رقم',
            value: function (model) {
                return model.number;
            }
        }

    ];
    const actions = [];
    const params = ["status=0"]
    const {token} = Token();
    const ds = new dataService(token, 'cheque');
    const breadcrumb = [
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/taslim",
            name: "التسلیم الحوالات"
        },

    ];
    const onDoubleClick = async (id) => {
        ds.get(id)
            .then(response => {
                return <Navigate to={"/cheque-out/" + id}  />

            })
            .catch(error => {
                console.error(error);
            });
    };
    return (
        <div class="col-md-12">
            <Breadcrumb data={breadcrumb}/>
            <Datatable entity="users"
                       params={params}
                       dataService={ds}
                       columns={columns}
                       onDoubleClick={onDoubleClick}
                       actions={actions}
                       config={config}/>
        </div>
    )
};

export default Taslim
