import React,{useState} from "react";
import Dashboard from '../dashboard/dashboard'
import Breadcrumb from "../Breadcrumb";

const Home = () => {
    const [account,setAccount] = useState(null);
    const  breadcrumb=[
        {
            link: "/",
            name: "الرئيسية"
        }
    ]
    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
        <Dashboard onSelectActiveAccount={setAccount}/>
        </div>
    )
};

export default Home
