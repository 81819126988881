import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import Parser from "html-react-parser";
import "./dataTable.css";
import { useSelector } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { BaseUrl } from "../../utilities/StaticProvider";
import moment from "moment";
import jsPDF from "jspdf";
import Token from "../auth/Token";
import { applyPlugin } from "jspdf-autotable";
import Utilities from "../../utilities/utilities";
import ExportMethod from "../../helpers/export";
import NumberFormat from "react-number-format";
import "./AlHurraTxtBold-normal.js";

applyPlugin(jsPDF);

export default function AccountingDataTable(props) {
  const account = useSelector((state) => state.account);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: state.isFocused ? "#fff" : "#e8e8e8",
      borderRadius: "8px",
      borderColor: "#e8e8e8",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "red" : "blue",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [fromDate, setFromDate] = useState(
    new Date().setMonth(new Date().getMonth() - 1)
  );
  const [toDate, setToDate] = useState(new Date());
  const { token } = Token();
  const [state, setState] = useState({
    entities: {
      data: [],
      models: "",
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
      },
    },
    first_page: 1,
    current_page: 1,
    sorted_column: props.columns[0].key,
    offset: 4,
    order: "asc",
    q: "",
    account_id: account ? account.id : null,
    currency_id: null,
    from: moment(fromDate).startOf("day").format("YYYY/MM/DD HH:mm:ss"),
    to: moment(toDate).endOf("day").format("YYYY/MM/DD HH:mm:ss"),
    type: null,
    loader: true,
  });
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const loadAccounts = async () => {
    const response = (await fetch(BaseUrl + "account")).json();
    response.then((result) => {
      let options = [];
      result.data.map((item) => {
        options.push({ label: item.name, value: item.id });
      });
      setAccounts(options);
    });
  };

  const loadCurrencies = async () => {
    const response = await fetch(BaseUrl + "currency");
    const models = await response.json();
    setCurrencies(models.data);
    return models.data;
  };

  function deleteHandler(id) {
    props.dataService.delete(id);
    NotificationManager.success("item delete successfully", "delete item");
    fetchEntities();
    return true;
  }

  function fetchEntities() {
    return new Promise((resolve, reject) => {
      setState({ ...state, loader: true });
      return props.dataService
        .getAllFiltered({
          filter: {
            account_id: state.account_id,
            from: state.from,
            to: state.to,
            type: state.type,
            currency_id: state.currency_id,
          },
          pagination: {
            page: state.current_page,
            per_page: state.entities.meta.per_page,
          },
          order: {
            column: state.sorted_column,
            order: state.order,
          },
        })
        .then((response) => {
          let data = response.data.data.map((entity) => {
            return props.columns
              .map((item) => item.key)
              .reduce(
                (a, e) => (
                  (a[e] = props.columns
                    .find((item) => item.key === e)
                    .value(entity)),
                  a
                ),
                {}
              );
          });
          setState({
            ...state,
            entities: {
              ...state.entities,
              data: data,
              meta: response.data.meta,
              models: response.data.data,
            },
            loader: false,
          });
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    });
  }

  /**
   *
   * @param inputValue
   * @param callback
   */
  function fetchAutoComplete(inputValue, callback) {
    props.dataService.model = "accounts";
    return props.dataService
      .getAll({
        q: inputValue,
        per_page: 5,
      })
      .then((response) => {
        let options = [];
        response.data.data.map((entity) => {
          options.push({
            label: entity.name ? entity.name : "",
            value: entity.id,
          });
        });

        setAccounts(options);

        if (callback) {
          callback(options);
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
      .finally(() => {
        props.dataService.model = "transactions";
      });
  }

  function changePage(pageNumber) {
    state.current_page = pageNumber;
    fetchEntities();
  }

  function columnHead(value) {
    return value.split("_").join(" ").toUpperCase();
  }

  function pagesNumbers() {
    if (!state.entities.meta.to) {
      return [];
    }
    let from = state.entities.meta.current_page - state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + state.offset * 2;
    if (to >= state.entities.meta.last_page) {
      to = state.entities.meta.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  useEffect(async () => {
    if (account) {
      setSelectedOption({ value: account.id, label: account.name });
    }
    loadAccounts();
    loadCurrencies()
      .then((currencies) => {
        currencies.slice(0, 3).forEach((item) => {
          let currencyId = item.currency_id;

          props.columns.push({
            key: item.name,
            title: item.name,
            value: function (model) {
              if (+item.id === +model.currency_id) {
                let className = "badge-success";
                let sign = "+";
                if (+model.type === 1) {
                  className = "badge-danger";
                  sign = "-";
                }
                return (
                  <NumberFormat
                    value={model.price}
                    displayType={"text"}
                    allowNegative={true}
                    defaultValue={0}
                    thousandSeparator={true}
                    prefix={""}
                    renderText={(formattedValue, customProps) => {
                      return (
                        <span className={"badge ltr " + className}>
                          {sign}
                          {formattedValue}
                        </span>
                      );
                    }}
                  />
                );
              }
              return 0;
            },
          });

          props.columns.push({
            key: item.name + "_",
            title: "المتبقي",
            value: function (model) {
              if (+item.id === +model.currency_id) {
                let amount = model.amounts.filter(
                  (a) => +a.currency_id === +item.id
                )[0]?.amount;
                let className = "badge-success";
                let sign = "+";
                if (amount < 0) {
                  className = "badge-danger";
                  sign = "-";
                }
                return (
                  <NumberFormat
                    value={amount}
                    displayType={"text"}
                    allowNegative={true}
                    defaultValue={0}
                    thousandSeparator={true}
                    prefix={""}
                    renderText={(formattedValue, customProps) => {
                      return (
                        <span className={"badge ltr " + className}>
                          {formattedValue}
                        </span>
                      );
                    }}
                  />
                );
              }
              return 0;
            },
          });
        });
      })
      .then((result) => {
        fetchEntities().then((result) => {
          if (props.onSelect) {
            !account && result[0] && props.onSelect(result[0].id);
          }
        });
      });
  }, []);

  function tableHeads() {
    let icon;
    if (state.order === "asc") {
      icon = <i className="fas fa-arrow-up" />;
    } else {
      icon = <i className="fas fa-arrow-down" />;
    }
    return props.columns.map((column) => {
      return (
        <th
          className="table-head"
          key={column.key}
          onClick={() => sortByColumn(column.key)}
        >
          {columnHead(column.title)}
          {column.key === state.sorted_column && icon}
        </th>
      );
    });
  }

  function changeQuery(inputValue, callback) {
    if (inputValue.length > 2) {
      fetchAutoComplete(inputValue, callback);
    }
  }

  async function navigateToModelEdit(id) {
    const models = state.entities.models;
    const model = models.find((item) => item.id === id);
    if (+model.entity_type === 1 || +model.entity_type === 2) {
      if (+model.type === 1) {
        navigate("/transfer/withdraw/" + model.entity_id);
      } else {
        navigate("/transfer/deposit/" + model.entity_id);
      }
    } else if (+model.entity_type === 3 || +model.entity_type === 4) {
      if (+model.type === 1) {
        navigate("/cheque-out/" + model.entity_id);
      } else {
        navigate("/cheque-in/" + model.entity_id);
      }
    } else if (+model.entity_type === 5 || +model.entity_type === 6) {
      if (+model.type === 1) {
        navigate("/exchange/buyer/" + model.entity_id);
      } else {
        navigate("/exchange/seller/" + model.entity_id);
      }
    } else {
      navigate("/");
    }
  }

  function filter(event) {
    event.preventDefault();
    fetchEntities();
  }

  function sortByColumn(column) {
    if (column === state.sorted_column) {
      state.order === "asc"
        ? setState(
            { ...state, order: "desc", current_page: state.first_page },
            () => {
              fetchEntities();
            }
          )
        : setState({ ...state, order: "asc" }, () => {
            fetchEntities();
          });
    } else {
      setState(
        {
          ...state,
          sorted_column: column,
          order: "asc",
          current_page: state.first_page,
        },
        () => {
          fetchEntities();
        }
      );
    }
  }

  function renderPagination() {
    return (
      <div className="col-md-12">
        {state.entities.data && state.entities.data.length > 0 && (
          <nav>
            <div className="customer-pagination pagination">
              <button
                disabled={1 === state.entities.meta.current_page}
                onClick={() => {
                  changePage(state.entities.meta.current_page - 1);
                }}
                className={
                  1 === state.entities.meta.current_page
                    ? "next"
                    : "next active"
                }
              >
                <span className="icon icon-Arrow pink reverse" />
              </button>

              <div className="indexes">
                {Array(
                  state.entities.meta.last_page <= 5
                    ? state.entities.meta.last_page
                    : 5
                )
                  .fill(0)
                  .map((item, index) => {
                    index =
                      state.entities.meta.last_page <= 5
                        ? index
                        : index + state.entities.meta.current_page - 1;
                    return (
                      <button
                        onClick={() => changePage(index + 1)}
                        className={
                          state.entities.meta.current_page === index + 1
                            ? "index active"
                            : "index"
                        }
                      >
                        {index + 1}
                      </button>
                    );
                  })}
              </div>

              <button
                disabled={
                  state.entities.meta.last_page ===
                  state.entities.meta.current_page
                }
                onClick={() => {
                  changePage(state.entities.meta.current_page + 1);
                }}
                className={
                  state.entities.meta.last_page ===
                  state.entities.meta.current_page
                    ? "prev"
                    : "prev active"
                }
              >
                <span className="icon icon-Arrow" />
              </button>
            </div>
          </nav>
        )}
      </div>
    );
  }

  function applyFilter(name = "", value = "") {
    if (name === "account_id") {
      state.account_id = value.value;
    } else if (name === "currency_id") {
      state.currency_id = value;
    } else if (name === "from") {
      if (value != null)
        state.from = moment(value).startOf("day").format("YYYY/MM/DD HH:mm:ss");
      else state.from = null;
      setFromDate(value);
    } else if (name === "to") {
      if (value != null)
        state.to = moment(value).endOf("day").format("YYYY/MM/DD HH:mm:ss");
      else state.to = null;
      setToDate(value);
    } else if (name === "daily") {
      state.type = "daily";
    } else if (name === "weekly") {
      state.type = "weekly";
    } else if (name === "monthly") {
      state.type = "monthly";
    } else if (name === "all") {
      state.type = "all";
    } else if (name === "correspond") {
      state.type = "correspond";
    } else if (name === "last_correspond") {
      state.type = "last_correspond";
    } else {
      state.type = null;
      state.from = null;
      state.to = null;
    }
    if (state.account_id === null && state.type === null) state.type = "all";
    fetchEntities();
  }

  function onExport(e, type) {
    this.onDataRender();

    const { exportHeaders } = this.props;
    const { data, header } = this.raw;

    const exportData = ExportMethod[type](data, exportHeaders ? header : null);

    Utilities.download(exportData);

    this.setState({ dropdown: false });

    e.preventDefault();
  }

  function onPrint() {
    this.onDataRender();

    const { data, header } = this.raw;
    const table = ExportMethod.print(data, header);

    Utilities.print(table);
  }

  function renderFilters() {
    return (
      <div className="col-md-12">
        <form className="datatable-filterbox-accounting" onSubmit={filter}>
          <div className="form-row">
            <div className="col-md-3 form-group">
              <div className="title">اسم المشتری :</div>
              <div className="input">
                <AsyncSelect
                  defaultOptions={accounts}
                  loadOptions={changeQuery}
                  value={selectedOption}
                  name="account_id"
                  styles={customStyles}
                  className="minimal"
                  isClearable
                  onChange={(e, triggeredAction) => {
                    setSelectedOption(e);
                    if (e !== null) {
                      applyFilter("account_id", e);
                    } else {
                      applyFilter("account_id", { value: null });
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">اختر نوع العمل :</div>
              <div className="input">
                <Select
                  options={
                    currencies &&
                    currencies.map((item) => {
                      return { value: item.id, label: item.name };
                    })
                  }
                  name="currency_id"
                  styles={customStyles}
                  isClearable
                  onChange={(e) =>
                    e
                      ? applyFilter("currency_id", e.value)
                      : applyFilter("currency_id", null)
                  }
                  className="minimal"
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">من تاریخ :</div>
              <div className="input">
                <DatePicker
                  name="from"
                  id="from"
                  className="form-control silver"
                  dateFormat="yyyy/MM/dd"
                  selected={fromDate}
                  strictParsing
                  closeOnScroll={true}
                  onChange={(date) => applyFilter("from", date)}
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">الی تاریخ :</div>
              <div className="input">
                <DatePicker
                  name="from"
                  id="from"
                  selected={toDate}
                  className="form-control silver"
                  dateFormat="yyyy/MM/dd"
                  strictParsing
                  closeOnScroll={true}
                  onChange={(date) => applyFilter("to", date)}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12 filter-checks">
              <div className="form-row">
                <div className="col-md-2 col-sm-6">
                  <label
                    className="el-switch el-switch-sm el-switch-red"
                    htmlFor="daily"
                  >
                    <span className="margin-l">اليومي</span>
                    <input
                      type="checkbox"
                      name="type"
                      checked={state.type === "daily"}
                      onChange={(date) =>
                        state.type === "daily"
                          ? applyFilter()
                          : applyFilter("daily", "set")
                      }
                      id="daily"
                    />
                    <span className="el-switch-style" />
                  </label>
                </div>
                <div className="col-md-2 col-sm-6">
                  <label
                    className="el-switch el-switch-sm el-switch-red"
                    htmlFor="weekly"
                  >
                    <span className="margin-l">الاسبوعي</span>
                    <input
                      type="checkbox"
                      name="type"
                      checked={state.type === "weekly"}
                      onChange={(date) =>
                        state.type === "weekly"
                          ? applyFilter()
                          : applyFilter("weekly", "set")
                      }
                      id="weekly"
                    />
                    <span className="el-switch-style" />
                  </label>
                </div>
                <div className="col-md-2 col-sm-6">
                  <label
                    className="el-switch el-switch-sm el-switch-red"
                    htmlFor="monthly"
                  >
                    <span className="margin-l">الشهري</span>
                    <input
                      type="checkbox"
                      name="type"
                      checked={state.type === "monthly"}
                      onChange={(date) =>
                        state.type === "monthly"
                          ? applyFilter()
                          : applyFilter("monthly", "set")
                      }
                      id="monthly"
                    />
                    <span className="el-switch-style" />
                  </label>
                </div>
                <div className="col-md-2 col-sm-6">
                  <label
                    className="el-switch el-switch-sm el-switch-red"
                    htmlFor="all"
                  >
                    <span className="margin-l">الکل</span>
                    <input
                      type="checkbox"
                      name="type"
                      checked={state.type === "all"}
                      onChange={(date) =>
                        state.type === "all"
                          ? applyFilter()
                          : applyFilter("all", "set")
                      }
                      id="all"
                    />
                    <span className="el-switch-style" />
                  </label>
                </div>
                <div className="col-md-2 col-sm-6">
                  <label
                    className="el-switch el-switch-sm el-switch-red"
                    htmlFor="correspond"
                  >
                    <span className="margin-l">فقط مطابقات</span>
                    <input
                      type="checkbox"
                      name="type"
                      checked={state.type === "correspond"}
                      onChange={(date) =>
                        state.type === "correspond"
                          ? applyFilter()
                          : applyFilter("correspond", "set")
                      }
                      id="correspond"
                    />
                    <span className="el-switch-style" />
                  </label>
                </div>
                <div className="col-md-2 col-sm-6">
                  <label
                    className="el-switch el-switch-sm el-switch-red"
                    htmlFor="last_correspond"
                  >
                    <span className="margin-l">من اخیر مطابقه</span>
                    <input
                      type="checkbox"
                      checked={state.type === "last_correspond"}
                      name="type"
                      onChange={(date) =>
                        state.type === "last_correspond"
                          ? applyFilter()
                          : applyFilter("last_correspond", "set")
                      }
                      id="last_correspond"
                    />
                    <span className="el-switch-style" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  function list() {
    if (state.entities.data.length) {
      return state.entities.data.map((model) => {
        return (
          <tr
            key={model.id}
            className={account && account.id === model.id ? "active" : ""}
            onClick={(event) => props.onSelect && props.onSelect(model.id)}
            onDoubleClick={() => navigateToModelEdit(model.id)}
          >
            {Object.keys(model).map((key) => (
              <td key={key}>{model[key]}</td>
            ))}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={props.columns.length} className="text-center">
            No Records Found.
          </td>
        </tr>
      );
    }
  }

  function exportPDF() {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const margin = 40;
    const doc = new jsPDF(orientation, unit, size);
    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.setFontSize(15);
    doc.setFont("AlHurraTxtBold");
    var headers = ["الترتیب", "التاریخ", "النوع", "الرقم"];
    currencies.forEach((value) => {
      headers.push(value.name);
      headers.push("المتبقي");
    });
    const title = "کشف الحساب";
    const pdf_headers = [headers.reverse()];

    const data = state.entities.data.map((item) => {
      const dts = Object.values(item).reverse();
      var items = [];
      dts.forEach((value) => {
        if (typeof value === "object") {
          items.push(value.props.children ?? value.props.value);
        } else items.push(value);
      });
      return items;
    });

    let content = {
      startY: 60,
      head: pdf_headers,
      body: data,
      theme: "grid",
      styles: {
        cellPadding: 2,
        font: "AlHurraTxtBold",
        halign: "right",
        valign: "middle",
      },
      headStyles: {
        cellPadding: 5,
        halign: "center",
        valign: "middle",
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 12,
      },
    };

    doc.text(title, pageWidth / 2, margin, { align: "center" });
    doc.autoTable(content);

    doc.save("report.pdf");
  }

  function render() {
    return (
      <div className="col-md-12">
        <div className="datatable">
          {props.config.export && (
            <div className="datatable-header export-div">
              <button className="btn" onClick={() => exportPDF()}>
                <span className={props.config.export.icon} />
              </button>
              <h5> {props.config.export.title}</h5>
            </div>
          )}
          {state.loader && <ShimmerTable row={4} />}
          {!state.loader && (
            <table className="table">
              <thead>
                <tr>{tableHeads()}</tr>
              </thead>
              <tbody>{list()}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="form-row">
      {renderFilters()}
      {render()}
      {renderPagination()}
    </div>
  );
}
