import React, {useEffect, useState} from "react";
import axios from 'axios';
import {BaseUrl} from './../../utilities/StaticProvider'
import Alert from './../../utilities/Alert/AlertMessages';
import {useParams} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import avii from "../../message/avii";
import Breadcrumb from "../Breadcrumb";
import translate from "../../message/avii";
import {useNavigate} from "react-router";

const Form = () => {

    let { Id } = useParams()
    const [currency, setCurrency] = useState([]);

    const navigate = useNavigate();

    useEffect(async () => {
        let dataCurrency = {};
        if (Id){
             dataCurrency=await axios.get(BaseUrl + 'currencies/'+Id);
            setCurrency(dataCurrency.data.data)
        }
    }, []);


    let name = null;
    let purchasePrice = null;
    let salesPrice = null;
    let description = null;

    let createOrUpdate = (e) => {
        e.preventDefault();
        let data = {
            name: name.value,
            purchasePrice: purchasePrice.value,
            salesPrice: salesPrice.value,
            description: description.value,

        };



        let title;
        let message="";
        if (Id){
            message=translate.ar.forms["Updated successfully"];
            title=translate.ar.global["update"];
        }else{
            message=translate.ar.forms["created successfully"]
            title=translate.ar.global["create"];
        }
        let option={
            method:Id ?"put":"post",
            url:Id ?BaseUrl+"currencies/"+Id:BaseUrl+"currencies",
            data: data
        }
        axios(option).then((response) => {
            NotificationManager.success(message,title );
            navigate("/currency")
        }, (error) => {
            NotificationManager.error(translate.ar.global["error"],title);
        });
    }



    function render() {
        return (
            <div className="form-row card">
                <div className="card-header">
                    <span className="icon icon-Omala"></span>
                    <span className="text-black">{avii.ar.forms["create currency"]}</span>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="exampleInputEmail1">نام</label>
                            <input defaultValue={currency.name} name="name" ref={el => name = el} type="text"
                                   className="inputStyle form-control " id="exampleInput"
                                   aria-describedby="name"/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="exampleInputEmail1">قیمت خرید</label>
                            <input ref={el => purchasePrice = el} type="number" className="inputStyle form-control "
                                   id="exampleInput"
                                   aria-describedby="purchasePrice" defaultValue={currency.purchasePrice}/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="exampleInputEmail1">قیمت فروش</label>
                            <input ref={el => salesPrice = el} type="number" className="inputStyle form-control "
                                   id="exampleInput"
                                   aria-describedby="salesPrice" defaultValue={currency.salesPrice}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="exampleInputEmail1">توضیحات</label>
                            <textarea ref={el => description = el}
                                      className="inputStyle form-control "
                                      defaultValue={currency.description}
                                      rows="5"
                            >

                                    </textarea>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <button type="submit" id="submit2" className="btn btn-sm btn-success margin30">تسجیل
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const  breadcrumb=[
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/setting",
            name: "اعدادات"
        },
        {
            link: "/currency",
            name: "قائمة"
        },
        {
            link:Id? "/currency/update":"/currency/create/",
            name: Id? "تعدیل":"انشاء",
        }
    ]
    return (
        <div>
            <Breadcrumb data={breadcrumb}/>

        <form className="form" id="currency-form" onSubmit={createOrUpdate}>
            <div className="form-row">
                {render()}
            </div>
        </form>
        </div>
    )


}

export default Form

