import React from "react";
import Header from '../header/header'
import Menu from '../menu/menu'
import './layout.css';
import {useSelector} from "react-redux";
import {NotificationContainer} from "react-notifications";


const Layout = ({children}) => {
    const menu = useSelector((state) => state.menu);
    return (
        <div className="wrapper">
            <aside className={ menu ? 'main-sidebar open-menu' : 'main-sidebar close-menu' }>
                <div className="sidebar">
                    <Menu/>
                </div>
            </aside>
            <div className="content-wrapper">
                <header className="main-header navbar navbar-expand">
                    <Header/>
                </header>
                {children}
            </div>
            <NotificationContainer/>
        </div>

    )
}

export default Layout
