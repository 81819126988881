import React, {useEffect, useState} from "react";
import './userList.css';
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import Breadcrumb from "../Breadcrumb";

const UserList = (props) => {
    const config = {
        create: {
            link: './create',
            icon: 'icon-Plus',
            title: 'تعریف المستخدم'
        }
    };
    const columns = [
        {
            key: 'id',
            title: 'الترتیب',
            value: function (model) {
                return model.id;
            }
        },
        {
            key: 'username',
            title: 'اسم المستخدم',
            value: function (model) {
                return Parser(
                    `<div class="account-info">
                                <div className="customer-avatar">
                                    <img src="./assets/images/man.png" alt="">
                                </div>
                                <div className="account-name"> ${model.username}</div>
                            </div>
                        `
                );
            }
        },
        {
            key: 'name',
            title: 'اسم المستخدم',
            value: function (model) {
                return model.name;
            }
        },
        {
            key: 'created_at',
            title: 'تاريخ الانشاء',
            value: function (model) {
                return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
            }
        },
        {
            key: 'name',
            title: '',
            value: function (model) {
                return '';
            }
        }
    ];
    const actions = [
        {
            link: '/users/update/{id}',
            icon: 'icon icon-Edit',
            type : 'link'
        },
        {
            link: '/users/delete/{id}',
            icon: 'icon icon-Cancel',
            type : 'delete'
        }
    ];
    const {token} = Token();
    const ds = new dataService(token, 'users');
    const  breadcrumb=[
        {
            link: "/",
            name: "الرئيسية"
        },
        {
            link: "/setting",
            name: "اعدادات"
        },
        {
            link: "/users",
            name: "قائمة"
        },

    ]
    return (
        <div class="col-md-12">
            <Breadcrumb data={breadcrumb}/>
            <Datatable entity="users"
                       dataService={ds}
                       columns={columns}
                       actions={actions}
                       config={config}/>
        </div>
        )
};

export default UserList
