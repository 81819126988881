import React, {useEffect, useState} from "react";
import moment from "moment";
import Token from "../auth/Token";
import './header.css';
import connect from "react-redux/es/connect/connect";
import {useSelector} from "react-redux";

const Header = (props) => {
    const menu = useSelector((state) => state.menu);
    const [state, setState] = useState({
        user: {
            name: ""
        },
        date: "",
        time: ""
    });



    const {getUser} = Token();

    useEffect(() => {
        let date = moment().format("DD-MM-YYYY");
        let time = moment().format("hh:mm");
        let user = getUser();
        setState({user, date, time})
    }, []);

    return (

        <div className="row" id="top-info">
            <div className="col-lg-4 col-xs-12">
                <div className="user-info">
                    <div className="user-info-title">
                        <span className="user-info-title-role"> المستخدم : </span>
                        <strong className="user-info-title-name"> {state.user.username} </strong>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 pl-md-1 pr-md-1 col-md-6 col-xs-12">
                <div className="year-info">
                    <div className="icon icon-ALtarikh"/>
                    <div className="title">
                        <span>السنه المالیه :</span>
                        <strong>2021</strong>
                    </div>
                </div>
            </div>
            <div className="col-lg-4  col-md-6 col-xs-12">
                <div className="date-info">
                    <div className="icon icon-ALtarikh"/>
                    <div className="title">
                        <span>التاریخ :</span>
                        <strong className="date">{state.date}</strong>
                    </div>
                    <strong className="time">{state.time}</strong>
                </div>
            </div>
            <div className="info-notification">
                <button>
                    <span className="icon icon-Adadat"/>
                    <span className="notif"/>
                </button>
            </div>

            <a className="menu-toggle" href="#" role="button" onClick={()=>props.toggleMenu(menu)}>
                <i className="icon icon-Menu"/>
            </a>

        </div>
    );
};



const mapStateToProps = (state) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleMenu: (menu) => dispatch({type: 'TOGGLE_MENU',payload : !menu})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
